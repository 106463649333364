<template>
<form class="modal-backdrop" @click.self="$emit('close')" @submit.prevent="sendData">
  <div class="modal">
    <div class="title regular bolder d-flex justify-space-between">
<span class="d-flex">
  <span>سفارش</span>
  <span :class="{'green':buy , 'red' :!buy}" >{{buy? 'خرید' : 'فروش'}}</span>

</span>
      <img src="../../assets/Icons/close.svg" alt="" @click.prevent="$emit('close')">
    </div>
    <span class="small align-self-start gray">آیا از سفارش خود مطمئن هستید؟</span>
<div class="d-flex justify-space-between small gray">
  <span>مقدار</span>
  <span class="ltr">{{$toLocal(Amount,$decimal[coin.coin])}} </span>
</div>
    <div class="d-flex justify-space-between small gray" v-if="buy">
      <span>کارمزد</span>
      <span>{{fee}} {{coin.code}}</span>
    </div>
    <div class="d-flex justify-space-between small gray">
      <span>دریافتی</span>
      <span class="ltr">{{$toLocal(totalPrice,toman? $decimal['TOMAN']: $decimal['TETHER'])}} {{coin.code}}  </span>
    </div>

    <button class="btn-primary  w100"> تایید نهایی</button>
  </div>


</form>
</template>

<script>
export default {
  name: "finalConfirm",
  props:['fee','buy','coin','Amount','totalPrice','toman','unitPrice'],
  data(){
    return{
      data: {
        marketType: this.toman?(`${this.coin.coin}_TOMAN`):(`${this.coin.coin}_TETHER`),
        amount: this.$S2N(this.Amount),
        sourceCoin: "TOMAN",
        destinationCoin: this.coin.coin,
        orderType: this.buy?('EXCHANGE_BUY'):('EXCHANGE_SELL'),
        // tokenType: "",
        // destinationWallet: "",
        unitPrice: this.$S2N(this.unitPrice),
        totalPrice: this.$S2N(this.totalPrice),
        wage: this.wage,
      },
    }
  },
  methods:{
    async sendData(){
      this.$emit('close')
      const res =await this.$axios.post(`/exchange/${this.buy?'buy' : 'sell'}` , this.data);
    if(res.message.includes('created')){
      this.$error('سفارش با موفقیت ثبت شد','','success')
    }
    
    }
  },
  mounted(){

  }
}
</script>

<style lang="scss" scoped>


.modal {
  display: flex;
  flex-direction: column;
  //align-items: center;
  flex-grow: 1;
  max-width: 400px;
  min-height: 200px;
  background: var(--darker-white);
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 24px;
  gap: 24px;
}
button{
  height: 44px;
}

</style>