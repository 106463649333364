<template>

  <div

    class="otc d-flex flex-col justify-content-center align-items-center"
    @click.self="paymentDropDown = false"
  >
    <div class="buttons d-flex w100 align-items-center">
      <button
        class="w100"
        @click.prevent="buy = true;tomanTetherWallet()"
        :class="{ 'btn-green': buy }"
      >
        خرید
      </button>
      <button
        class="w100"
        @click.prevent="buy = false;getCredit()"
        :class="{ 'btn-red': !buy }"
      >
        فروش
      </button>
    </div>
    <!--    wallet info-->
    <div
      class="d-flex justify-space-between align-items-center gray regular w100"
    >
      <span>موجودی شما:</span>
      <span>{{ buy?$toLocal(buyCredit, $decimal["TOMAN"]) : $toLocal(sellCredit, $decimal[coin])  }}</span>
    </div>

    <!--    payment info-->
    <div class="d-flex flex-col w100 coin-container">
      <div class="d-flex justify-end payment-container pointer">
        <button
          class="d-flex gap-xss align-items-center pointer"
          @click="paymentDropDown = !paymentDropDown"
        >
          <img src="../../assets/Icons/trade-selector.svg" alt="" />
          <span>{{ selectedPayment.title }} </span>
          <img
            :src="'/coins/' + selectedPayment.value + '.png'"
            alt=""
            width="36"
            height="36"
          />
        </button>
        <div class="drop-down d-flex flex-col gap-xss" v-if="paymentDropDown">
          <span
            class="d-flex justify-space-between"
            v-for="(payment, index) in payment"
            :key="index"
            @click="changePayment(payment)"
          >
            <span>{{ payment.title }}</span>
            <img
              :src="'/coins/' + payment.value + '.png'"
              alt=""
              width="18"
              height="18"
            />
          </span>
        </div>
      </div>

      <div class="label-container">
        <div :class="ftotal ? 'div-active' : ''" class="input-p2p">
          <label>
            <span :class="ftotal || ftotal ? 'span-active-p2p' : ''">
              {{ buy? 'پرداختی شما':'دریافتی شما'}}
            </span>
            <input
              @input="inputsFun('totalPrice')"
              v-model="data.totalPrice"
              @focus="ftotal = true"
              type="text"
              disabled
            />
          </label>
        </div>
      </div>
      
    </div>

    <!--    coins info-->

    <div class="d-flex flex-col w100 coin-container">
      <div class="d-flex justify-end payment-container">
        <button
          class="d-flex gap-xss align-items-center pointer"
          @click="isCoinSelector = !isCoinSelector"
        >
          <img src="../../assets/Icons/trade-selector.svg" alt="" />
          <span>{{ `${coin.persianName}      (${coin.code})` }}</span>

          <img
            :src="'/coins/' + coin.code + '.png'"
            alt=""
            width="36"
            height="36"
          />
        </button>
        <div class="drop-down d-flex flex-col gap-xss" v-if="coinsDropDown">
          <span
            class="d-flex justify-space-between"
            v-for="(coin, index) in coins"
            :key="index"
            @click="changeCoin(coin)"
          >
            <span>{{ coin.persianName }}</span>
            <img
              :src="'/coins/' + coin.code + '.png'"
              alt=""
              width="18"
              height="18"
            />
          </span>
        </div>
      </div>

      <div class="label-container">
        <div :class="famount ? 'div-active' : ''" class="input-p2p">
          <label>
            <span :class="famount || famount ? 'span-active-p2p' : ''"
              > مقدار</span
            >
            <input
              @input="inputsFun('amount')"
              v-model="data.amount"
              @focus="famount = true"
              type="text"
            />
          </label>
        </div>
      </div>
    </div>

    <div class="d-flex justify-space-between w100">
      <span class="gray small">کارمزد</span>
      <span class="gray small">{{ data.wage + "%" }}</span>
    </div>
    <div class="d-flex justify-space-between w100">
      <span class="gray small">مقدار قابل ارائه</span>
      <span class="gray small ltr">
        {{ minAmountExchange + "  -  " + maxAmountExchange }} {{ coin.code }}
      </span>
    </div>
    <div
      class="
        d-flex
        align-items-center
        justify-start
        small
        gray
        ltr
        gap-xss
        w100
      "
    >
      <span class="d-flex gap-xss align-items-center justify-end">
        1 {{ coin.persianName }} ({{ coin.code }})
      </span>

      <span class="secondary">~</span>

      <span class="d-flex gap-xss align-items-center">
        {{
          $toLocal(
            data.unitPrice,
            this.toman ? $decimal["TOMAN"] : $decimal["TETHER"]
          )
        }}

        ({{ this.toman ? "TMN" : "USDT" }})
      </span>
    </div>

    <button
      class="w100 submit"
      :disabled="buy ? !!buyReadOnly : !!sellReadOnly"
      :class="{ 'btn-green': buy, 'btn-red': !buy }"
      @click="isOrder = !isOrder"
    >
      {{
        buy
          ? !!buyReadOnly
            ? buyReadOnly
            : "خرید"
          : !!sellReadOnly
          ? sellReadOnly
          : "فروش"
      }}
    </button>

    <transition enter-active-class="animate__animated animate__fadeInUp">
      <CoinSelector
        v-if="isCoinSelector"
        :paymentType="paymentType"
        @close="isCoinSelector = false"
        @coin="(value) => (coin = value)"
      />
    </transition>

    <FinalConfirm
      v-if="isOrder"
      :total-price="data.totalPrice"
      :Amount="data.amount"
      :coin="coin"
      :buy="buy"
      :fee="data.wage"
      :toman="toman"
      :unitPrice="data.unitPrice"
      @close="isOrder = false"
    />
  </div>
</template>

<script>
import CoinSelector from "@/components/OTC/CoinSelector.vue";
import FinalConfirm from "@/components/OTC/finalConfirm.vue";
import {Loop} from '../../Lib/reuseableFunction'
export default {
  name: "Exchange",
  components: { FinalConfirm, CoinSelector },
  data() {
    return {
      //UI variables
      buy: true,
      selectedPayment: "",
      isOrder: false,
      payment: [
        {
          title: "تومان (TMN)",
          value: "TOMAN",
        },
        {
          title: "تتر (USDT)",
          value: "TETHER",
        },
      ],
      paymentDropDown: false,
      coinsDropDown: false,
      isCoinSelector: false,
      calculating: false,
      //amounts
      ftotal: false,
      famount: false,
      Amount: null,
      toman: true,
      totalPrice: null,
      paymentType: "TOMAN",
      coins: [],
      fee: 2,
      coin: "",
      minAmountExchange: null,
      buyPrice: null,
      sellPrice: null,
      maxAmountExchange: null,
      price: 100000000,
              buyCredit:'',
        sellCredit:'',
      data: {
        marketType: "TRON_TOMAN",
        amount: null,
        sourceCoin: "TOMAN",
        destinationCoin: "BITCOIN",
        orderType: "EXCHANGE_BUY",

        // tokenType: "",
        // destinationWallet: "",
        unitPrice: null,
        totalPrice: null,
        dollar: null,
        wage: 0.3,
      },
    };
  },
  methods: {
    //loop
        beforeLoop() {
      this.state.loading = false;
      this.calculating = true;
      if (this.loop) {
        this.loop.stop();
      }
      this.loading = true;
      if (this.state.loop) {
        this.loop = new Loop(
            this.getPrice,
            5000,
            "getPrice" + (this.toman ? "Toman" : "Tether")
        );
        this.loop.start();
      } else {
        this.getPrice();
      }
    },
    async unitFun() {
      this.data.totalPrice = "";
      if (this.loop) {
        this.loop.stop();
      }
      this.toman = !this.toman;
      // $emit('changeUnit',toman);
      // calcTotalPrice();calcAmount();
      await this.tomanTetherWallet();
      this.beforeLoop();
    },
    async inputsFun(e) {
      this.calculating = true;
      if (e === "amount") {
        this.data.amount = this.$toLocal(
          this.data.amount,
          this.$decimal[this.coin]
        );
      } else {
        this.data.totalPrice = this.$toLocal(
          this.data.totalPrice,
          this.$decimal["TOMAN"]
        );
      }

      this.fromAmount = e === "amount";
      if (this.loop) {
        this.loop.stop();
      }
      const temp = this.data[e];
      await this.$sleep(500);
      if (temp === this.data[e]) {
        this.beforeLoop();
      }
    },

    changePayment(e) {
      // console.log(e);

      this.selectedPayment = e;
      this.paymentDropDown = false;
      this.paymentType = e.value;
      e.value === "TOMAN" ? (this.toman = true) : (this.toman = false);
      this.tomanTetherWallet();
      // console.log(this.paymentType);
      // console.log(this.toman);
      this.getCoins();
      this.getPrice();
    },
    changeCoin(e) {
      this.coin = e;
      // console.log(this.coin);
      this.coinsDropDown = false;
      // this.getCoins();
      this.getPrice();
    },
    async getCoins() {
      this.state.loading = true;
      const res = await this.$axios.get("/coins/details");
      this.paymentType === "TETHER"
        ? (this.coins = res.content.filter(
            (name) => name.coin !== "TOMAN" && name.coin !== "TETHER"
          ))
        : (this.coins = res.content.filter((name) => name.coin !== "TOMAN"));
      this.coin = this.coins[0];
      this.minAmountExchange = this.coins.find((a) => a.coin == this.coin);
      this.maxAmountExchange = this.coins.find((a) => a.coin == this.coin);
      let coin = this.coins.find((a) => a.coin === this.coin.coin);
      // console.log(this.coin)
      this.data.wage = coin.tokenTypeDetails[0].withdrawalFee;
      // console.log(coin);
      this.minAmountExchange = coin.tokenTypeDetails[0].minWithdraw;
      this.maxAmountExchange = coin.tokenTypeDetails[0].maxWithdraw;
      this.data.wage = coin.tokenTypeDetails[0].withdrawalFee;

      // console.log('min:',this.minAmountExchange)
      // console.log('max:',this.maxAmountExchange)
      // console.log('fee',this.data.wage)
      // console.log(this.coin);
      this.state.loading = false;
    },



    calcTotalPrice() {
      this.data.totalPrice = this.$toLocal(
          this.$S2N(this.data.amount) * this.$S2N(this.data.unitPrice),
          this.$decimal[this.toman ? "TOMAN" : "TETHER"]
      );
    },
    calcAmount() {
      this.data.amount = this.$toLocal(
          this.$S2N(this.data.totalPrice) / this.$S2N(this.data.unitPrice),
          this.$decimal[this.coin]
      );
    },




async getCredit(){
      this.state.loading = true;
      const [res] = await this.$http.get(
          `/wallets/customer-addresses`,
          {
            params: {
              coins: `${this.coin.coin}`,
              walletType:'EXCHANGE'
            },
          }
      );
      if (res) {
        this.sellCredit = res[this.coin.coin].credit;
        console.log(this.sellCredit)
    
      }
      this.state.loading = false;
},


    async getPrice() {
      const res = await this.$axios.get(
        // "coins/exchange-price/"+ this.toman? 'usd' : 'usdt' +"?includeWeeklyChange=false&destCoinSymbol=" +
        //   this.coin

        `coins/exchange-price/${
          this.toman ? "usd" : "usdt"
        }?includeWeeklyChange=false&destCoinSymbol=${this.coin.coin}`
      );
      this.prices = res;

      let price = this.prices.find((a) => a.destCoinSymbol == this.coin.coin);
      this.buy
        ? (this.data.unitPrice = price.buyPrice)
        : (this.data.unitPrice = price.sellPrice);
      // console.log(price);

      if (this.fromAmount) {
        this.calcTotalPrice();
      } else {
        this.calcAmount();
      }
    },

    async tomanTetherWallet() {
      // this.loading = true
      const [res2] = await this.$http.get(
          `/wallets/customer-addresses?walletType=EXCHANGE&coins=${this.toman ? "TOMAN" : "TETHER"}`
      );
      if (res2) {

        this.buyCredit = this.toman ? res2["TOMAN"].credit : res2["TETHER"].credit;
        console.log(this.buyCredit)
      }
      this.beforeLoop()
      // this.loading = false
    },




  },
  watch: {
    coin() {
      this.getPrice();
    },
    buy() {
      this.getPrice();
    },
  },
  computed: {
    sellReadOnly() {
      let error = "";
      if (!this.$A2N(this.data.amount)) {
        error = "مقدار ارز اجباری است";
        // this.disableSell = true
      } else if (this.$S2N(this.data.amount) < this.minAmountExchange) {
        error = "حداقل مقدار رعایت نشده";
        // this.disableSell = true
      } else if (
        (this.buy &&
          this.$S2N(this.data.totalPrice) > this.sellCredit) ||
        (!this.buy && this.$S2N(this.data.amount) > this.sellCredit)
      ) {
        error = "مقدار بیش از موجودی شماست";
        // this.sellAmount = 'مقدار بیش از موجودی شماست'
        // this.disableSell = true
      } else if (this.$S2N(this.data.amount) > this.maxAmountExchange) {
        error = "حداکثر مقدار رعایت نشده";
        // this.sellAmount = 'حداکثر مقدار رعایت نشده'
        // this.disableSell = true
      }
      //  else if (this.calculating) {
      //   error = "لطفا صبر کنید ...";
      // }
      return error;
      //  else {
      //     this.disableSell = false
      //     this.sellAmount = ''
      // }
    },
    buyReadOnly() {
      let error = "";
      if (!this.$A2N(this.data.amount)) {
        error = "مقدار ارز اجباری است";
      } else if (this.$A2N(this.data.amount) < this.minAmountExchange) {
        error = "حداقل مقدار رعایت نشده";
      } else if (
        this.$A2N(this.data.totalPrice) > this.buyCredit
      ) {
        error = "مقدار بیش از موجودی شماست";
      } else if (
        this.$S2N(this.data.amount) > this.$A2N(this.maxAmountExchange)
      ) {
        error = "حداکثر مقدار رعایت نشده";
      } 
      // else if (this.calculating) {
      //   error = "لطفا صبر کنید ...";
      // }

      return error;
    },
  },

  async mounted() {
    this.selectedPayment = this.payment[0];
    await this.getCoins();
await this.getCredit();
await this.tomanTetherWallet();
    await this.getPrice();
  },
};
</script>

<style lang="scss" scoped>
.otc {
  background: var(--white-darker);
  box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
  border-radius: 12px;
  padding: 28px 24px;
  width: 100%;
  max-width: 412px;
  gap: 24px;
}

.buttons {
  box-shadow: 0 0 2px rgba(140, 150, 153, 0.34);
  border-radius: 4px;
  width: 100%;
  height: 52px;
  padding: 0.5rem;

  button {
    transition: 700ms;
    height: 36px;
  }
}

.coin-container {
  gap: 1rem;
}

.payment-container {
  position: relative;

  .drop-down {
    position: absolute;
    top: 3em;
    width: 100%;
    max-width: 140px;
    background: var(--white);
    z-index: 3;
    max-height: 150px;
    overflow: scroll;
  }
}

.submit {
  height: 44px;
}

@media (max-width: 500px) {
  .otc {
    padding: 1rem !important;
  }
}
</style>