<template>
  <div class="modal-backdrop" @click.self="$emit('close')">

    <div class="modal">
      <h3 class="align-items-center">انتخاب کوین</h3>
      <div class="label-container">
        <div :class="fsearch? 'div-active':''" class="input-p2p">
          <label>
          <span class="d-flex gap-xss" :class="fsearch || fsearch ? 'span-active-p2p':''">
            <img src="../../assets/mag.png" alt="" width="12" height="12" class="align-self-center">
          جستجو

          </span>
            <input
                @focus="fsearch=true" v-model="regex" type="text">

          </label>
        </div>
      </div>
      <div class="coin-container d-flex flex-col justify-center">
        <div v-for="(coin,index) in searchCoin()" class="row d-flex w100 gap-xss" :key="index">
          <img :src="'/coins/' + coin.code + '.png'" alt="" width="36" height="36">

          <span class="d-flex flex-col gap-xss" @click.prevent="selectCoin(coin)">
            <span class="regular">{{ coin.persianName }}</span>
            <span class="small gray">{{ coin.code }}</span>
          </span>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CoinSelector",

  data() {
    return {
      fsearch: false,
      regex: '',
      coins: [],
      coin: null,
    }


  },
  props: ['paymentType'],
  methods: {

    selectCoin(e) {
      console.log(e)
      this.$emit('coin', e)
      this.$emit('close')
    },

    async getCoins() {
      this.state.loading = true;
      const res = await this.$axios.get("/coins/details");
      this.paymentType === 'TETHER' ? this.coins = res.content.filter((name) => name.coin !== "TOMAN" && name.coin !== 'TETHER') : this.coins = res.content.filter((name) => name.coin !== "TOMAN");
      // this.minAmountExchange = this.coins.find(a => a.coin == this.coin)
      // this.maxAmountExchange = this.coins.find(a => a.coin == this.coin)
      // let coin = this.coins.find(a => a.coin === this.coin.coin);
      // // console.log(this.coin)
      // this.data.wage = coin.tokenTypeDetails[0].withdrawalFee
      // console.log(coin)
      // this.minAmountExchange = coin.tokenTypeDetails[0].minWithdraw
      // this.maxAmountExchange = coin.tokenTypeDetails[0].maxWithdraw
      // this.data.wage = coin.tokenTypeDetails[0].withdrawalFee

      // console.log('min:',this.minAmountExchange)
      // console.log('max:',this.maxAmountExchange)
      // console.log('fee',this.data.wage)
      // console.log(this.coin)
      this.state.loading = false;
    },
    searchCoin() {
      return this.coins.filter((coin) => {
        console.log(this.regex);
        console.log(coin);
        if (coin.code.toUpperCase().includes(this.regex.toUpperCase()) || coin.persianName.includes(this.regex) || coin.englishName.toUpperCase().includes(this.regex.toUpperCase()) || coin.coin.toUpperCase().includes(this.regex.toUpperCase())) {

          return coin;
        }
      });
    },
  },
  mounted() {
    this.getCoins()
  }
}
</script>

<style lang="scss" scoped>
.modal-backdrop{
  background-color: transparent !important;
}
.modal {
  display: flex;
  flex-direction: column;
  //align-items: center;
  flex-grow: 1;
  max-width: 400px;
  min-height: 200px;
  background: var(--darker-white);
  box-shadow: 0px 4px 9px rgba(94, 94, 94, 0.25);
  border-radius: 8px;
  position: relative;
  padding: 24px;
  gap: 24px;
  max-height: 527px;
}

.coin-container {
  max-height: 360px;
  overflow: auto;

  .row {
    border-bottom: 1px solid var(--gray);
    padding: .5rem 0;
  }
}

.label-container {

  input {
    width: 70%;
  }

  img {
    width: 12px;
    height: 12px;
  }
}


</style>